<template>
  <div class="sidebar">
    <DocumentList v-if="navOpen" />
    <UserMenu v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import DocumentList from '@/js/components/document/list.vue';
import UserMenu from '@/js/components/navigation/user-menu.vue';

export default defineComponent({
  name: 'NavSidebar',
  components: {
    DocumentList,
    UserMenu,
  },
  props: {
    navOpen: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
