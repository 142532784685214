<template>
  <article data-panel="scroll">
    <slot />
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BooksPanel',
});
</script>
