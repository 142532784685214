<template>
  <BooksAlerts />
  <RouterView data-app v-bind="$attrs" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BooksAlerts from '@/js/components/alerts.vue';

export default defineComponent({
  name: 'BooksRoot',
  components: {
    BooksAlerts,
  },
});
</script>

<style lang="scss">
@use '@/sass/app';
</style>
