// eslint-disable-next-line import/no-unresolved
import 'vite/modulepreload-polyfill';

import { createPinia } from 'pinia';
import { createApp } from 'vue';

import Root from '@/js/root.vue';
import getRouter from '@/js/router';
import useSentry from '@/js/sentry';
import { useUserStore } from '@/js/stores/user';
import { registerGlobalComponents } from '@/js/utils/components';
import { getColors } from '@/js/utils/sassColors';

const app = (window.BOOKS = createApp(Root));

const pinia = createPinia();
app.use(pinia);

// User store must be hydrated before router is setup,
// but after `app.use(pinia)` is called.
const userStore = useUserStore();
userStore.hydrate();

const router = getRouter();
useSentry(app, router);

app.use(router);
registerGlobalComponents(app);

window.addEventListener('load', () => {
  if (userStore.user !== null) {
    userStore.loggedIn();
  }

  window.UTILS = {
    COLORS: getColors(),
  };

  app.mount('[data-layout="root"]');
});
