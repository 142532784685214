<template>
  <component
    :is="tag"
    :data-icon="name"
    :data-icon-size="size"
    :data-icon-appearance="appearance"
    :data-status="status"
    aria-hidden="true"
    focusable="false"
  />
</template>

<script lang="ts">
import { camelCase, upperFirst } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BooksIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    appearance: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag(): string {
      return `Icon${upperFirst(camelCase(this.name))}`;
    },
  },
});
</script>
