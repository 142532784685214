<template>
  <h2 class="panel-title-wrapper can-truncate">
    <span class="panel-title">{{ title }}</span>
  </h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>
