import { onBeforeUnmount, onMounted, ref } from 'vue';

import { ARIAdw, ARIAdwOptions } from '@/js/vendor/aria-disclosure-widget';

export default function useDisclosure(
  options: Partial<typeof ARIAdwOptions> = {},
) {
  const disclosure = ref<HTMLElement | null>(null);
  const teardown = ref<(() => void) | false>(false);

  onMounted(() => {
    if (disclosure.value) {
      teardown.value = ARIAdw(disclosure.value, options);
    }
  });

  onBeforeUnmount(() => {
    if (teardown.value) {
      teardown.value();
    }
  });

  return { disclosure };
}
