import { acceptHMRUpdate, defineStore } from 'pinia';

import {
  BooksService,
  type VersionCreate,
  type VersionRead,
  type VersionUpdate,
} from '@/js/api';

export interface VersionsState {
  // Map of all known Versions
  versions: Map<VersionRead['id'], VersionRead>;
  // List of versions currently being fetched
  fetching: Set<number>;
  // List of document ids whose versions have been fetched
  fetchedDocuments: number[];
}

export const useVersionsStore = defineStore('versions', {
  state: (): VersionsState => ({
    versions: new Map(),
    fetching: new Set(),
    fetchedDocuments: [],
  }),

  getters: {
    versionById:
      (state) =>
      (versionId: number): VersionRead | null =>
        state.versions.get(versionId) || null,

    versionsByDoc:
      (state) =>
      (docId: number): VersionRead[] =>
        [...state.versions.values()].filter(
          (v: VersionRead) => v.document === docId,
        ),

    fetchingVersion:
      (state) =>
      (versionId: number): boolean =>
        state.fetching.has(versionId),

    hasFetchedVersionsForDocument:
      (state) =>
      (docId: number): boolean =>
        state.fetchedDocuments.includes(docId),
  },

  actions: {
    setVersion(version: VersionRead) {
      this.versions.set(version.id, version);
    },

    removeVersion(id: number) {
      this.versions.delete(id);
    },

    setVersions(versions: VersionRead[]) {
      if (!versions.length) return;
      for (const version of versions) {
        this.setVersion(version);
      }
    },

    async createVersion(data: VersionCreate) {
      try {
        const response = await BooksService.versionCreate({
          requestBody: data,
        });
        this.setVersion(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async updateVersion(id: number, data: VersionUpdate) {
      try {
        const response = await BooksService.versionUpdate({
          id,
          requestBody: data,
        });
        this.setVersion(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async archiveVersion(id: number) {
      try {
        const response = await BooksService.versionArchive({ id });
        this.setVersion(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async unarchiveVersion(id: number) {
      try {
        const response = await BooksService.versionUnarchive({ id });
        this.setVersion(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async shareVersion(id: number, share: boolean) {
      try {
        const response = await BooksService.versionShare({
          id,
          requestBody: { share },
        });
        this.setVersion(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },
  },
});

/* c8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVersionsStore, import.meta.hot));
}
