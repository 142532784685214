<template>
  <AccountRegisterUserForm />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccountRegisterUserForm from '@/js/components/account/register-user-form.vue';

export default defineComponent({
  name: 'RegisterUserView',
  components: { AccountRegisterUserForm },
});
</script>
