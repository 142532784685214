import { createRouter, createWebHistory, type Router } from 'vue-router';

import { requireAuth } from '@/js/router/navigationGuards';
import routes from '@/js/router/routes';

let router: Router;

const getRouter = () => {
  if (!router) {
    router = createRouter({
      history: createWebHistory(),
      routes,
      scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
      },
    });
    router.beforeEach(requireAuth);
  }
  return router;
};

export default getRouter;
