import { computed } from 'vue';

import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';

export default function useDocumentsList() {
  const documentsStore = useDocumentsStore();
  const socketStore = useSocketStore();
  const connected = computed(() => socketStore.connected);
  const documents = computed(() => documentsStore.orderedDocuments.slice(0, 4));

  return {
    connected,
    documents,
    addDocument: documentsStore.addDocument,
  };
}
