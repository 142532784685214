<template>
  <UtilityModal data-modal="archive" open @close="$emit('close')">
    <div class="modal-instructions">
      <span class="icon-offset">
        <BooksIcon name="archive" size="medium" appearance="inverted" />
      </span>
      <h2>Confirm Document Archive</h2>
      <p>
        You are about to archive all versions of the document
        <strong>{{ documentTitle }}</strong
        >.
      </p>
    </div>
    <!-- <ul>
      <li>Collaborators' versions of this document will not be archived.</li>
      <li>You will still be a collaborator on this document.</li>
      <li>Visit the Archive to restore this document.</li>
    </ul> -->
    <div class="modal-actions">
      <button
        type="button"
        class="modal-action"
        data-btn="wide"
        :disabled="!connected || archiving"
        @click="doArchive"
      >
        Archive Document
      </button>
    </div>
  </UtilityModal>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';

import { closeDocumentPanels } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'DocumentArchiveModal',
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    documentTitle: {
      type: String,
      required: true,
    },
    connected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      archiving: false,
    };
  },
  methods: {
    ...mapActions(useDocumentsStore, ['archiveDocument']),
    async doArchive() {
      this.archiving = true;
      const archived = await this.archiveDocument(
        this.documentId,
        this.documentTitle,
      );
      this.archiving = false;
      this.$emit('close');
      if (archived) {
        closeDocumentPanels(this.documentId);
      }
    },
  },
});
</script>
