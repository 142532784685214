<template>
  <div data-view="public">
    <header class="nav-header">
      <h1 class="visually-hidden">OddBooks Editor</h1>
      <IconLink
        href="/"
        icon-name="logo-small"
        icon-size="medium"
        hidden-text="OddBooks.app"
        rel="home"
      />
    </header>
    <main data-panels>
      <RouterView />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PublicView',
});
</script>
