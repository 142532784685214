<template>
  <div class="not-found">
    <header class="header-404">
      <p class="error-404">404</p>
      <h2 v-if="panelIndex === null" class="title-404">
        Page Not Found. That's odd!
      </h2>
      <p>But it's <em>bound</em> to happen sometimes.</p>
    </header>
    <div class="empty-panel">
      <h3 class="panel-title">Maybe one of these?</h3>
      <template v-if="documents.length">
        <header class="list-header">
          <h3 class="list-title">Recent Documents</h3>
        </header>
        <VersionList
          v-for="document in documents"
          :key="document.id"
          :panel-index="panelIndex"
          :document="document"
          hide-actions
        />
      </template>
      <IconButton
        v-if="connected"
        icon-name="add"
        data-btn="icon-text wide list-add"
        text="Add New Document"
        @click="addDocument"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import VersionList from '@/js/components/version/list.vue';
import useDocumentsList from '@/js/composables/useDocumentsList';

export default defineComponent({
  name: 'NotFound',
  components: {
    VersionList,
  },
  props: {
    panelIndex: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return useDocumentsList();
  },
});
</script>
