<template>
  <AccountResetPasswordForm />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccountResetPasswordForm from '@/js/components/account/reset-password-form.vue';

export default defineComponent({
  name: 'ResetPasswordView',
  components: { AccountResetPasswordForm },
});
</script>
