<template>
  <ul v-if="alerts.size" id="app-alerts" data-list="plain" class="notices">
    <li v-for="[id, alert] in alerts" :key="id" :data-alert="alert.type">
      <BooksIcon
        :name="alert.type === ALERT_TYPES.SUCCESS ? 'checkmark' : 'warning'"
        size="medium"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-if="alert.safe" class="alert" v-html="alert.message" />
      <span v-else class="alert">{{ alert.message }}</span>
      <IconButton
        icon-name="cross"
        icon-size="small"
        hidden-text="Remove message"
        @click="removeAlert(id)"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import { ALERT_TYPES } from '@/js//utils/constants';
import { useAlertsStore } from '@/js/stores/alerts';

export default defineComponent({
  name: 'BooksAlerts',
  setup() {
    return { ALERT_TYPES };
  },
  computed: {
    ...mapState(useAlertsStore, ['alerts']),
  },
  methods: {
    ...mapActions(useAlertsStore, ['removeAlert']),
  },
});
</script>
