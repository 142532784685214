<template>
  <UtilityFormField
    :title="title"
    field-id="password"
    :error="showValidation ? error : undefined"
  >
    <input
      id="password"
      ref="input"
      :value="modelValue"
      :disabled="disabled"
      type="password"
      name="password"
      data-input="text"
      :autocomplete="autocomplete"
      :minlength="newPassword && minPassword ? minPassword : undefined"
      required
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
      @blur="showValidation = true"
    />
  </UtilityFormField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountFieldPassword',
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: { type: String, required: true },
    newPassword: { type: Boolean, default: false },
  },
  emits: {
    'update:modelValue': (_value: string) => true,
  },
  data() {
    return {
      error: '',
      showValidation: false,
    };
  },
  computed: {
    minPassword(): number {
      return window.CONFIG?.PASSWORD_MIN_LENGTH || 0;
    },
    autocomplete() {
      return this.newPassword ? 'new-password' : 'current-password';
    },
    title() {
      return this.newPassword ? 'New Password' : 'Password';
    },
  },
  watch: {
    modelValue() {
      const input = this.$refs.input as HTMLInputElement;
      this.error = input.validationMessage;
    },
  },
});
</script>
