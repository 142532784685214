/* Based on http://www.aaronsw.com/2002/diff/diff.py by Aaron Swartz */

import { SequenceMatcher } from '@/js/vendor/difflib.cjs';

const isWord = (c: string): RegExpMatchArray | null => c.match(/[a-zA-Z-_]/);

const html2list = (x: string): string[] => {
  let mode = 'char';
  let cur = '';
  const out = [];
  for (let i = 0; i < x.length; i = i + 1) {
    const c = x[i];
    switch (mode) {
      case 'tag':
        cur = cur + c;
        if (c === '>') {
          out.push(cur);
          cur = '';
          mode = 'char';
        }
        break;
      case 'char':
        if (c === '<') {
          out.push(cur);
          cur = c;
          mode = 'tag';
        } else if (isWord(c)) {
          cur = cur + c;
        } else {
          out.push(cur + c);
          cur = '';
        }
        break;
    }
  }
  out.push(cur);
  return out.filter((w) => w !== '');
};

export default function htmldiff(a: string, b: string) {
  const out = [];
  const x = html2list(a);
  const y = html2list(b);
  const s = new SequenceMatcher(null, x, y).getOpcodes();
  for (let i = 0; i < s.length; i = i + 1) {
    const e = s[i];
    if (e[0] === 'replace') {
      const left = x.slice(e[1], e[2]);
      const right = y.slice(e[3], e[4]);
      const leftPlain = left.filter((w) => w[0] !== '<').join('');
      const rightPlain = right.filter((w) => w[0] !== '<').join('');
      if (leftPlain === rightPlain) {
        out.push(`<mark class="diff style">${right.join('')}</mark>`);
      } else {
        out.push(
          `<del class="diff modified">${left.join(
            '',
          )}</del><ins class="diff modified">${right.join('')}</ins>`,
        );
      }
    } else {
      switch (e[0]) {
        case 'delete':
          out.push(`<del class="diff">${x.slice(e[1], e[2]).join('')}</del>`);
          break;
        case 'insert':
          out.push(`<ins class="diff">${y.slice(e[3], e[4]).join('')}</ins>`);
          break;
        case 'equal':
          out.push(y.slice(e[3], e[4]).join(''));
          break;
        default:
          throw new Error(`Unexpected opcode: ${e[0]}`);
      }
    }
  }
  const res = out.join('');
  return res;
}
