<template>
  <header class="nav-header">
    <h1 class="visually-hidden">OddBooks Editor</h1>
    <IconButton
      icon-name="stack"
      :hidden-text="navOpen ? 'Close document list' : 'Open document list'"
      :aria-pressed="[navOpen ? 'true' : 'false']"
      data-btn="icon toggle"
      @click="handleClick"
    />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavHeader',
  props: {
    navOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['header-clicked'],
  methods: {
    handleClick() {
      this.$emit('header-clicked');
    },
  },
});
</script>
