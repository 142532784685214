<template>
  <UtilityLoading v-if="fetching" />
  <PanelDocumentDetail
    v-else-if="document && version"
    :panel-index="panelIndex"
    :document="document"
    :version-id="version.id"
    :version-public-id="version.public_id"
    :version-title="version.title"
    :is-hidden="version.deleted !== null"
    :text="version.text"
    :modified-at="version.text_modified_at"
    :word-count="version.word_count"
  />
  <PanelNotFound v-else :panel-index="panelIndex" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import PanelDocumentDetail from '@/js/components/panels/document-detail.vue';
import PanelNotFound from '@/js/components/panels/not-found.vue';
import useFetchVersion from '@/js/composables/useFetchVersion';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'VersionDetailPanelView',
  components: {
    PanelDocumentDetail,
    PanelNotFound,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const documentsStore = useDocumentsStore();
    const { version, fetching } = useFetchVersion(props);

    const document = computed(() =>
      version.value
        ? documentsStore.documentById(version.value.document)
        : null,
    );

    return {
      fetching,
      document,
      version,
    };
  },
});
</script>
