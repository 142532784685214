<template>
  <UtilityModal open @close="$emit('close')">
    <div class="modal-instructions">
      Select one or more versions of this document to compare to the
      <strong>current version</strong> (<UtilityDate
        :value="document.text_modified_at"
      />).
    </div>
    <UtilityLoading v-if="fetching" />
    <div v-else class="content-wrapper modal-list">
      <div data-panel="scroll-content flex">
        <header class="list-header">
          <h2 class="list-title">Saved Versions</h2>
          <VersionToggleHidden
            v-if="hasHiddenVersions"
            :show-hidden="showHidden"
            @toggle="showHidden = !showHidden"
          />
        </header>
        <div class="versions-list">
          <ul class="list-body" data-list="plain versions">
            <li
              v-for="version in visibleVersions"
              :key="version.id"
              class="list-item version-compare-item"
              data-listitem="version"
              :data-hidden="version.deleted !== null || null"
            >
              <input
                :id="`compare-version-${version.id}`"
                v-model="selectedVersions"
                type="checkbox"
                name="compare-versions"
                class="hidden-checkbox"
                :value="version.id"
              />
              <label :for="`compare-version-${version.id}`" data-meta="docinfo">
                <VersionListItem
                  :version-id="version.id"
                  :title="version.title || document.title"
                  :modified-at="version.text_modified_at"
                  :word-count="version.word_count"
                  :is-shared="version.public_id !== null"
                  :connected="connected"
                />
              </label>
            </li>
            <span v-if="!versions.length" data-message="empty">
              There are no older saved versions of this document yet.
            </span>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-actions">
      <button
        v-if="selectedVersions.length"
        type="button"
        class="modal-action"
        data-btn
        @click="openCompare"
      >
        Compare
      </button>
    </div>
  </UtilityModal>
</template>

<script lang="ts">
import { map, reject, without } from 'lodash';
import {
  computed,
  defineComponent,
  type PropType,
  ref,
  toRefs,
  watch,
} from 'vue';

import type { DocumentRead } from '@/js/api';
import VersionListItem from '@/js/components/version/list-item.vue';
import VersionToggleHidden from '@/js/components/version/toggle-hidden.vue';
import useFetchVersions from '@/js/composables/useFetchVersions';
import useHiddenVersions from '@/js/composables/useHiddenVersions';
import { navigatePanel } from '@/js/router/panels';

export default defineComponent({
  name: 'VersionCompareModal',
  components: {
    VersionListItem,
    VersionToggleHidden,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    document: {
      type: Object as PropType<DocumentRead>,
      required: true,
    },
    connected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { document, panelIndex } = toRefs(props);
    const docId = computed(() => document.value.id);
    const { versions, fetching } = useFetchVersions(docId);
    const selectedVersions = ref<number[]>([]);
    const { hasHiddenVersions, showHidden, visibleVersions } =
      useHiddenVersions(versions);

    const openCompare = async () => {
      /* c8 ignore next 3 */
      if (!selectedVersions.value.length) {
        return;
      }
      let openPanelAfterIdx = panelIndex.value;
      for (const id of selectedVersions.value) {
        await navigatePanel({
          to: `compare-${docId.value}-${id}`,
          panelIndex: openPanelAfterIdx,
          openAfter: true,
        });
        openPanelAfterIdx = openPanelAfterIdx + 1;
      }
      emit('close');
    };

    watch(showHidden, () => {
      if (!showHidden.value) {
        const hiddenVersionIds = map(
          reject(versions.value, { deleted: null }),
          'id',
        );
        selectedVersions.value = without(
          selectedVersions.value,
          ...hiddenVersionIds,
        );
      }
    });

    return {
      fetching,
      hasHiddenVersions,
      openCompare,
      selectedVersions,
      showHidden,
      versions,
      visibleVersions,
    };
  },
});
</script>
