import { camelCase, upperFirst } from 'lodash';
import type { App, Component } from 'vue';

import BooksIcon from '@/js/components/icons/icon.vue';
import IconButton from '@/js/components/icons/icon-button.vue';
import IconLink from '@/js/components/icons/icon-link.vue';

const registerComponents = (
  app: App,
  modules: Record<string, Component>,
  prefix?: string,
) => {
  for (const path in modules) {
    const component = modules[path];

    // Gets the file name regardless of folder depth
    let componentSlug = (path.split('/').pop() as string).replace(/\.\w+$/, '');
    if (prefix) {
      componentSlug = `${prefix}-${componentSlug}`;
    }
    // Get PascalCase name of component
    const componentName = upperFirst(camelCase(componentSlug));

    // Register component globally
    app.component(componentName, component);
  }
};

// Globally register component-groups to be used frequently
export const registerGlobalComponents = (app: App) => {
  const icons: Record<string, Component> = import.meta.glob(
    '@/assets/icons/*.svg',
    {
      import: 'default',
      eager: true,
    },
  );
  registerComponents(app, icons, 'icon');
  app.component('BooksIcon', BooksIcon);
  app.component('IconLink', IconLink);
  app.component('IconButton', IconButton);
  const utility: Record<string, Component> = import.meta.glob(
    '@/js/components/utility/*.vue',
    {
      import: 'default',
      eager: true,
    },
  );
  registerComponents(app, utility, 'utility');
};
