<template>
  <AccountSetNewPasswordForm :token="token" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccountSetNewPasswordForm from '@/js/components/account/set-new-password-form.vue';

export default defineComponent({
  name: 'SetNewPasswordView',
  components: { AccountSetNewPasswordForm },
  props: {
    token: { type: String, default: undefined },
  },
});
</script>
