<template>
  <div data-load="app">
    <IconLoadingPaper />
    <span class="loading-text">loading…</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import IconLoadingPaper from '@/../templates/loading-paper.svg';

/* Any changes to this file should also be applied in `templates/index.html` */

export default defineComponent({
  name: 'UtilityLoading',
  components: {
    IconLoadingPaper,
  },
});
</script>
