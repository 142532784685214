/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_reset_forgot_password } from '../models/Body_reset_forgot_password';
import type { Body_reset_reset_password } from '../models/Body_reset_reset_password';
import type { Body_verify_request_token } from '../models/Body_verify_request_token';
import type { Body_verify_verify } from '../models/Body_verify_verify';
import type { login } from '../models/login';
import type { OAuth2AuthorizeResponse } from '../models/OAuth2AuthorizeResponse';
import type { UserCreatePublic } from '../models/UserCreatePublic';
import type { UserRead } from '../models/UserRead';
import type { UserUpdate } from '../models/UserUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * Auth:Cookie.Login
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authCookieLogin({
        formData,
        next,
    }: {
        formData: login,
        next?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            query: {
                'next': next,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Auth:Cookie.Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authCookieLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/logout',
            errors: {
                401: `Missing token or inactive user.`,
            },
        });
    }
    /**
     * Verify:Request-Token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static verifyRequestToken({
        requestBody,
    }: {
        requestBody: Body_verify_request_token,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/request-verify-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Verify:Verify
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static verifyVerify({
        requestBody,
    }: {
        requestBody: Body_verify_verify,
    }): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Register:Register
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static registerRegister({
        requestBody,
    }: {
        requestBody: UserCreatePublic,
    }): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset:Forgot Password
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetForgotPassword({
        requestBody,
    }: {
        requestBody: Body_reset_forgot_password,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset:Reset Password
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetResetPassword({
        requestBody,
    }: {
        requestBody: Body_reset_reset_password,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Oauth:Google.Cookie.Authorize
     * @returns OAuth2AuthorizeResponse Successful Response
     * @throws ApiError
     */
    public static oauthGoogleCookieAuthorize({
        scopes,
        next,
    }: {
        scopes?: Array<string>,
        next?: string,
    }): CancelablePromise<OAuth2AuthorizeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/google/authorize',
            query: {
                'scopes': scopes,
                'next': next,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Oauth:Google.Cookie.Callback
     * The response varies based on the authentication backend used.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static oauthGoogleCookieCallback({
        code,
        codeVerifier,
        state,
        error,
    }: {
        code?: (string | null),
        codeVerifier?: (string | null),
        state?: (string | null),
        error?: (string | null),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/google/callback',
            query: {
                'code': code,
                'code_verifier': codeVerifier,
                'state': state,
                'error': error,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Users:Current User
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static usersCurrentUser(): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/me',
            errors: {
                401: `Missing token or inactive user.`,
            },
        });
    }
    /**
     * Users:Patch Current User
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static usersPatchCurrentUser({
        requestBody,
    }: {
        requestBody: UserUpdate,
    }): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/auth/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Missing token or inactive user.`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Users:User
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static usersUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Missing token or inactive user.`,
                403: `Not a superuser.`,
                404: `The user does not exist.`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Users:Patch User
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static usersPatchUser({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UserUpdate,
    }): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/auth/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Missing token or inactive user.`,
                403: `Not a superuser.`,
                404: `The user does not exist.`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Users:Delete User
     * @returns void
     * @throws ApiError
     */
    public static usersDeleteUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/auth/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Missing token or inactive user.`,
                403: `Not a superuser.`,
                404: `The user does not exist.`,
                422: `Validation Error`,
            },
        });
    }
}
