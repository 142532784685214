<template>
  <dialog ref="modal" data-modal @close="$emit('close')">
    <IconButton
      icon-name="cross"
      icon-size="small"
      hidden-text="close"
      title="close"
      class="modal-close"
      @click="$emit('close')"
    />
    <slot />
  </dialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue';

export default defineComponent({
  name: 'UtilityModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const { open } = toRefs(props);
    const modal = ref<HTMLDialogElement | null>(null);

    const showModal = () => {
      modal.value?.showModal();
    };

    const closeModal = () => {
      modal.value?.close();
    };

    onMounted(() => {
      if (open.value) {
        showModal();
      }
    });

    watch(open, (isOpen) => {
      if (isOpen) {
        showModal();
      } else {
        closeModal();
      }
    });

    return {
      modal,
    };
  },
});
</script>

<style lang="scss" scoped>
dialog {
  border: none;
  grid:
    'modal-close' min-content
    'modal-instructions' min-content
    'modal-content' min-content
    'modal-actions' var(--modal-actions-row, min-content) / 1fr;
  max-height: calc(100vh - var(--spacer));
  overflow: var(--dialog-overflow);
  padding: 0; // for single column scroll bar to touch right side
}

[open] {
  display: grid;
}

.modal-close {
  grid-area: modal-close;
  justify-content: flex-end;
  margin-block-start: var(--gutter);
  margin-inline-end: var(--gutter);
  margin-inline-start: auto;
}
</style>
