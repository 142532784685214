<template>
  <main id="not-found" data-panels>
    <NotFound />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import NotFound from '@/js/components/not-found.vue';

export default defineComponent({
  name: 'NotFoundView',
  components: {
    NotFound,
  },
});
</script>

<style lang="scss" scoped>
#not-found {
  grid-row-end: span 2;
}
</style>
