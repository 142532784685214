<template>
  <disclosure-inline ref="disclosure" data-popup>
    <slot name="icon">
      <IconButton
        tag="div"
        icon-name="more"
        hidden-text="open menu"
        data-insert-trigger
      />
    </slot>
    <disclosure-content>
      <ul data-list="plain">
        <slot />
      </ul>
    </disclosure-content>
  </disclosure-inline>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useDisclosure from '@/js/composables/useDisclosure';

export default defineComponent({
  name: 'UtilityMenu',
  setup() {
    const { disclosure } = useDisclosure();

    return { disclosure };
  },
});
</script>
