<template>
  <UtilityLoading v-if="fetching" />
  <PublicReadOnly
    v-else-if="document"
    :title="document.title"
    :text="document.text"
    :authors="document.owners"
    :modified-at="document.text_modified_at"
    :word-count="document.word_count"
    is-current
  />
  <PublicReadOnly
    v-else-if="version"
    :title="version.title"
    :text="version.text"
    :authors="[version.user]"
    :modified-at="version.text_modified_at"
    :word-count="version.word_count"
    :document-title="version.document"
  />
  <PublicNotFound v-else />
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';

import PublicNotFound from '@/js/components/public/not-found.vue';
import PublicReadOnly from '@/js/components/public/read-only.vue';
import useFetchPublicView from '@/js/composables/useFetchPublicView';

export default defineComponent({
  name: 'PublicReadView',
  components: {
    PublicReadOnly,
    PublicNotFound,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    publicId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { publicId, type } = toRefs(props);
    const { document, version, fetching } = useFetchPublicView({
      publicId,
      type,
    });

    return {
      fetching,
      document,
      version,
    };
  },
});
</script>
