<template>
  <div data-field>
    <label data-label :for="fieldId"
      >{{ title }}
      <span v-if="description" data-label-description>{{
        description
      }}</span></label
    >
    <slot />
    <div
      v-if="error"
      aria-hidden="true"
      data-field-error
      :data-testid="`error-${fieldId}`"
    >
      <BooksIcon name="warning" size="medium" /><span>{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilityFormField',
  props: {
    fieldId: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, default: undefined },
    error: { type: String, default: undefined },
  },
});
</script>
