import { ApiError } from '@/js/api';
import getRouter from '@/js/router';
import { useAlertsStore } from '@/js/stores/alerts';
import { ALERT_TYPES } from '@/js/utils/constants';
import { logError } from '@/js/utils/logging';

export function errorHandler(error: unknown) {
  if (error instanceof Error || typeof error === 'string') {
    logError(error);
  }

  const router = getRouter();
  const addAlert = useAlertsStore().addAlert;
  if (error instanceof ApiError) {
    // Skip generic 404 alert
    if (error.status === 404) return;

    switch (error.body.detail) {
      case 'REGISTER_USER_ALREADY_EXISTS':
        addAlert({
          type: ALERT_TYPES.WARNING,
          message: 'This account already exists. Please sign in.',
        });
        router.push({ name: 'Login' });
        return;
      case 'VERIFY_USER_ALREADY_VERIFIED':
        // This is handled directly in `confirm-email-form.vue`
        return;
      case 'LOGIN_USER_NOT_VERIFIED':
        addAlert({
          type: ALERT_TYPES.WARNING,
          message:
            'Please confirm your account by clicking the confirmation link in your email.',
        });
        return;
      case 'LOGIN_BAD_CREDENTIALS':
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: 'You have entered an invalid email or password.',
        });
        return;
    }

    if (
      error.body.detail?.code === 'REGISTER_INVALID_PASSWORD' &&
      error.body.detail?.reason
    ) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.body.detail.reason,
      });
      return;
    }

    if (error.body.detail) {
      if (Array.isArray(error.body.detail)) {
        for (const details of error.body.detail) {
          if (details.msg) {
            addAlert({
              type: ALERT_TYPES.ERROR,
              message: details.msg,
            });
          }
        }
        return;
      }

      addAlert({
        type: ALERT_TYPES.ERROR,
        message: `${error.message}: ${error.body.detail}`,
      });
      return;
    }

    addAlert({
      type: ALERT_TYPES.ERROR,
      message: error.message,
    });
  }
}
