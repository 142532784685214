<template>
  <PanelMyAccount :panel-index="panelIndex" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import PanelMyAccount from '@/js/components/panels/my-account.vue';

export default defineComponent({
  name: 'MyAccountPanelView',
  components: {
    PanelMyAccount,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
});
</script>
