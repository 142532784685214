import type { Dictionary } from 'lodash';

export const logError = (
  message: string | Error,
  data: Dictionary<unknown> = {},
) => {
  if (window.Sentry) {
    window.Sentry.withScope((scope) => {
      scope.setExtras(data);
      if (window.Sentry) {
        if (message instanceof Error) {
          window.Sentry.captureException(message);
        } else {
          window.Sentry.captureMessage(message);
        }
      }
    });
  }
  window.console.error(message, data);
};

export const log = (...args: unknown[]) => {
  window.console.info(...args);
};
