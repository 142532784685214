<template>
  <div :data-user-palette="`avatar-${color}`">
    <BooksIcon name="avatar" :size="size" />
    <span v-if="hiddenText" class="visually-hidden">{{ hiddenText }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserIcon',
  props: {
    color: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: '',
    },
    hiddenText: {
      type: String,
      default: '',
    },
  },
});
</script>
