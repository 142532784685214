<template>
  <article data-panel="scroll">
    <div class="header-wrapper">
      <header data-header="panel">
        <div class="header-title">
          <DocumentTitle :title="title" />
          <div class="breadcrumb">
            <span v-if="isCurrent" class="can-truncate">Current Version</span>
            <span v-else class="can-truncate">
              <em class="panel-from">from </em>{{ documentTitle }}
            </span>
            <span class="can-truncate">
              | <em class="panel-from">by </em>{{ authors.join(', ') }}
            </span>
            <span class="can-truncate">
              | <em class="panel-from">modified </em>
              <UtilityDate :value="modifiedAt" />
              | {{ wordCount }} {{ wordCount === 1 ? 'word' : 'words' }}
            </span>
          </div>
        </div>
      </header>
    </div>
    <div ref="container" class="content-wrapper">
      <!-- eslint-disable vue/no-v-html -->
      <div data-panel="scroll-content" class="books-canvas" v-html="text" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import DocumentTitle from '@/js/components/document/title.vue';

export default defineComponent({
  name: 'PublicReadOnly',
  components: {
    DocumentTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    authors: {
      type: Array as PropType<string[]>,
      required: true,
    },
    modifiedAt: {
      type: String,
      required: true,
    },
    wordCount: {
      type: Number,
      required: true,
    },
    documentTitle: {
      type: String,
      default: null,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
