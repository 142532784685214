<template>
  <PanelVersionList
    v-if="document"
    :panel-index="panelIndex"
    :document="document"
  />
  <PanelNotFound v-else :panel-index="panelIndex" />
</template>

<script lang="ts">
import { toNumber } from 'lodash';
import { computed, defineComponent } from 'vue';

import PanelNotFound from '@/js/components/panels/not-found.vue';
import PanelVersionList from '@/js/components/panels/version-list.vue';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'VersionListPanelView',
  components: {
    PanelNotFound,
    PanelVersionList,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const documentsStore = useDocumentsStore();
    const docId = computed(() => {
      const id = toNumber(props.id);
      return isNaN(id) ? null : id;
    });
    const document = computed(() =>
      docId.value ? documentsStore.documentById(docId.value) : null,
    );

    return {
      document,
    };
  },
});
</script>
