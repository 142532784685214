<template>
  <div data-panel="scroll">
    <div class="content-wrapper">
      <div class="empty-panel">
        <h2 class="panel-title">What would you like to write?</h2>
        <template v-if="documents.length">
          <div data-list="documents">
            <header class="list-header">
              <h2 class="list-title">Recent Documents</h2>
            </header>
            <VersionList
              v-for="document in documents"
              :key="document.id"
              :document="document"
              hide-actions
            />
          </div>
        </template>
        <IconButton
          v-if="connected"
          icon-name="add"
          data-btn="icon-text wide list-add"
          text="Add New Document"
          @click="addDocument"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import VersionList from '@/js/components/version/list.vue';
import useDocumentsList from '@/js/composables/useDocumentsList';

export default defineComponent({
  name: 'BooksDashboard',
  components: {
    VersionList,
  },
  setup() {
    return useDocumentsList();
  },
});
</script>

<style lang="scss" scoped>
[data-panel~='scroll'] {
  --panel-grid: 'panel-mode panel-mode panel-mode' minmax(0, auto)
    '. panel-content .' 1fr / var(--panel-columns);
}

.empty-panel {
  --emptypanel-padding-top: calc(var(--header-height) + var(--gutter));
}
</style>
