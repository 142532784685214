<template>
  <IconButton
    :icon-name="showHidden ? 'eye' : 'eye-cross'"
    text="Hidden"
    is-secondary
    :hidden-text="
      showHidden ? 'do not show hidden versions' : 'show hidden versions'
    "
    :aria-pressed="showHidden"
    @click="$emit('toggle')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VersionToggleHidden',
  props: {
    showHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
});
</script>
