<template>
  <UtilityModal data-modal="share" open @close="$emit('close')">
    <div class="modal-instructions">
      <span class="icon-offset">
        <BooksIcon name="users" size="medium" appearance="inverted" />
      </span>
      <h2>Share {{ isCurrent ? 'Current (Live)' : 'Saved' }} Version</h2>
      <p data-share-modal-text>
        <strong>Share a link to a read-only view.</strong>
        Anyone with the link can view this
        {{ isCurrent ? 'document' : 'version' }} while it is set to
        <em>shared</em>. Toggling to <em>private</em>
        {{ isCurrent ? 'or archiving this document ' : '' }}deletes the link and
        removes public access.
        <template v-if="!isCurrent">
          Hiding a saved version will not delete the link.
        </template>
      </p>
      <p v-if="isCurrent" data-share-modal-text="small">
        You are sharing the current view of this document, which will update as
        you save new changes. If you want to share the current state without
        future updates, first save a new version and then share that saved
        version instead.
      </p>
    </div>
    <div class="toggle-switch">
      <component :is="isShared ? 'span' : 'strong'">Private</component>
      <span class="toggle-wrapper">
        <button
          class="toggle-button"
          role="switch"
          type="button"
          :aria-pressed="isShared"
          :aria-disabled="!connected || sharing"
          @click="share"
        >
          <span class="visually-hidden">
            {{ isCurrent ? 'Share document' : 'Share version' }}
          </span>
        </button>
        <span aria-hidden="true" class="toggle-background"></span>
        <span aria-hidden="true" class="toggle-switcher"></span>
      </span>
      <component :is="isShared ? 'strong' : 'span'">Shared</component>
    </div>
    <div class="modal-actions">
      <input
        class="share-link"
        :class="{ 'not-visible': !isShared }"
        type="text"
        data-input="text"
        readonly
        :value="shareLink"
        @focus="($event.currentTarget as HTMLInputElement | null)?.select()"
      />
      <Transition name="fade" mode="out-in">
        <IconButton
          v-if="shared"
          icon-name="checkmark"
          icon-size="small"
          text="Copied"
        />
        <button
          v-else
          :class="{ 'not-visible': !isShared }"
          type="button"
          data-btn
          @click="copyLink"
        >
          Copy Link
        </button>
      </Transition>
    </div>
  </UtilityModal>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { defineComponent, type PropType } from 'vue';

import { useDocumentsStore } from '@/js/stores/documents';
import { useVersionsStore } from '@/js/stores/versions';

export default defineComponent({
  name: 'DocumentShareModal',
  props: {
    versionId: {
      type: Number,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
    publicId: {
      type: String as PropType<string | null>,
      default: null,
    },
    connected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      sharing: false,
      shared: false,
    };
  },
  computed: {
    isShared() {
      return this.publicId !== null;
    },
    shareLink() {
      if (!this.isShared) return '';
      return `${window.location.origin}/read/${this.isCurrent ? 'document' : 'version'}-${this.publicId}`;
    },
  },
  methods: {
    ...mapActions(useDocumentsStore, ['shareDocument']),
    ...mapActions(useVersionsStore, ['shareVersion']),
    async share() {
      if (!this.connected || this.sharing) return;
      this.sharing = true;
      if (this.isCurrent) {
        await this.shareDocument(this.versionId, !this.isShared);
      } else {
        await this.shareVersion(this.versionId, !this.isShared);
      }
      this.sharing = false;
    },
    async copyLink() {
      if (!this.isShared) return;
      await navigator.clipboard.writeText(this.shareLink);
      this.shared = true;
      setTimeout(() => {
        this.shared = false;
      }, 3000);
    },
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--timing-fast) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
