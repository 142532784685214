<template>
  <component
    :is="tag"
    type="button"
    :data-btn="
      text ? (isSecondary ? 'icon-text secondary' : 'icon-text') : 'icon'
    "
  >
    <BooksIcon
      :name="iconName"
      :size="iconSize"
      :status="iconStatus"
      :appearance="iconAppearance"
    />
    <span v-if="text" class="btn-text">{{ text }}</span>
    <span v-if="hiddenText" class="visually-hidden">{{ hiddenText }}</span>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconStatus: {
      type: String,
      default: '',
    },
    iconAppearance: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hiddenText: {
      type: String,
      default: '',
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
