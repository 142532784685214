import { computed, type Ref, toValue } from 'vue';

import { useSocketStore } from '@/js/stores/socket';
import { useVersionsStore } from '@/js/stores/versions';

export default function useDisableVersionsList(
  docId: number | null | Ref<number | null>,
) {
  const connected = computed(() => useSocketStore().connected);
  const hasFetchedVersionsForDocument = computed(() => {
    const id = toValue(docId);
    if (id === null) return false;
    return useVersionsStore().hasFetchedVersionsForDocument(id);
  });
  const disableVersionsList = computed(
    () => !connected.value && !hasFetchedVersionsForDocument.value,
  );

  return disableVersionsList;
}
