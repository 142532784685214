import { acceptHMRUpdate, defineStore } from 'pinia';
import Sockette from 'sockette';

import { type DocumentRead, type VersionRead } from '@/js/api';
import {
  type ObjectType,
  WEBSOCKET_TYPES,
  type WebsocketAction,
  type WebsocketSubscribeAction,
} from '@/js/utils/constants';
import { log } from '@/js/utils/logging';

type UrlParams = Record<
  string,
  | string
  | number
  | boolean
  | undefined
  | null
  | (string | number | boolean | undefined | null)[]
>;

export interface Subscription {
  id: string | number;
  model: ObjectType;
  action: WebsocketSubscribeAction;
  lookup_by?: string | number;
  query_params?: UrlParams;
}

export interface Broadcast {
  type: 'broadcast';
  id: string | number;
  model: ObjectType;
  action: WebsocketAction;
  instance: { pk: string | number } | DocumentRead | VersionRead;
}

export interface Socket {
  subscribe: (data: Subscription, cb?: (payload: Broadcast) => void) => void;
  unsubscribe: (id: string | number) => void;
  close: Sockette['close'];
}

interface Options {
  timeout?: number;
  maxAttempts?: number;
  onopen?: (e?: Event) => void;
  onmessage?: (e?: Event) => void;
  onreconnect?: (e?: Event) => void;
  onmaximum?: (e?: Event) => void;
  onclose?: (e?: Event) => void;
  onerror?: (e?: Event) => void;
}

export interface SocketState {
  connected: boolean;
}

export const useSocketStore = defineStore('socket', {
  state: (): SocketState => ({ connected: true }),

  getters: {
    socket: () => window.socket,
  },

  actions: {
    createWindowSocket(socket: Socket) {
      window.socket = socket;
    },

    deleteWindowSocket() {
      Reflect.deleteProperty(window, 'socket');
    },

    resetStore() {
      this.delete();
    },

    hydrate(options: Options = {}) {
      /* c8 ignore next */
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = window.location.host;
      const baseUrl = `${protocol}//${host}`;
      const notificationUrl = `${baseUrl}${window.api_urls.ws_notifications()}`;
      const heartbeatUrl = `${baseUrl}${window.api_urls.ws_heartbeat()}`;
      const defaults = {
        heartbeat: 10000,
        timeout: 1000,
        maxAttempts: Infinity,
      };
      const opts = { ...defaults, ...options };

      let connected = false;
      let lostConnection = false;
      const pending = new Set();
      const subscriptions = new Map();
      const actions = new Map();

      const socket = new Sockette(notificationUrl, {
        timeout: opts.timeout,
        maxAttempts: opts.maxAttempts,
        onopen: () => {
          connected = true;
          for (const payload of pending) {
            log('[WebSocket] resolving pending subscription:', payload);
            socket.json(payload);
          }
          pending.clear();
          if (lostConnection) {
            lostConnection = false;
            log('[WebSocket] reconnected');
            for (const payload of subscriptions.values()) {
              log('[WebSocket] resubscribing:', payload);
              socket.json(payload);
            }
          } else {
            log('[WebSocket] connected');
          }
        },
        onmessage: (e) => {
          let data = e.data;
          try {
            data = JSON.parse(e.data);
          } catch (err) {
            // swallow error
          }
          log('[WebSocket] received:', data);
          const action = actions.get(data?.id);
          if (action) {
            action(data);
          }
        },
        onreconnect: () => {
          log('[WebSocket] attempting to reconnect…');
          if (!lostConnection) {
            lostConnection = true;
          }
        },
        onmaximum: () => {
          log(
            `[WebSocket] ending reconnect after ${opts.maxAttempts} attempts`,
          );
        },
        onclose: () => {
          log('[WebSocket] closed');
          connected = false;
        },
      });

      let heartbeatConnected = false;
      let heartbeatClosed = true;
      let waitingForPong = false;
      let heartbeatId: number | null = null;

      const heartbeat = new Sockette(heartbeatUrl, {
        timeout: opts.timeout,
        maxAttempts: opts.maxAttempts,
        onopen: () => {
          this.connected = true;
          heartbeatConnected = true;
          heartbeatClosed = false;
          waitingForPong = false;
          log('[Heartbeat] connected');
          if (heartbeatId !== null) {
            window.clearInterval(heartbeatId);
          }
          heartbeatId = window.setInterval(() => {
            if (waitingForPong) {
              log('[Heartbeat] offline');
              this.connected = false;
              heartbeatConnected = false;
            }
            waitingForPong = true;
            if (!heartbeatClosed) {
              heartbeat.send(WEBSOCKET_TYPES.PING);
            }
          }, opts.heartbeat);
        },
        onmessage: (e) => {
          if (e.data === WEBSOCKET_TYPES.PONG) {
            waitingForPong = false;
            if (!heartbeatConnected) {
              log('[Heartbeat] reconnected');
              this.connected = true;
              heartbeatConnected = true;
            }
          }
        },
        onmaximum: () => {
          log(
            `[Heartbeat] ending reconnect after ${opts.maxAttempts} attempts`,
          );
        },
        onclose: () => {
          log('[Heartbeat] closed');
          heartbeatClosed = true;
        },
      });

      const subscribe: Socket['subscribe'] = (data, cb) => {
        actions.set(data.id, cb);
        const payload = { ...data, type: WEBSOCKET_TYPES.SUBSCRIBE };
        subscriptions.set(data.id, payload);
        if (connected) {
          log('[WebSocket] subscribing to:', payload);
          socket.json(payload);
        } else {
          pending.add(payload);
        }
      };

      const unsubscribe: Socket['unsubscribe'] = (id) => {
        actions.delete(id);
        const payload = { id, type: WEBSOCKET_TYPES.UNSUBSCRIBE };
        subscriptions.delete(id);
        if (connected) {
          log('[WebSocket] unsubscribing from:', payload);
          socket.json(payload);
        } else {
          pending.add(payload);
        }
      };

      const close: Sockette['close'] = (...args) => {
        socket.close(...args);
        heartbeat.close(...args);
        if (heartbeatId !== null) {
          window.clearInterval(heartbeatId);
        }
      };

      this.createWindowSocket({
        subscribe,
        unsubscribe,
        close,
      });
    },

    delete() {
      if (this.socket) {
        this.socket.close(1000, 'user logged out');
      }
      this.connected = false;
      this.deleteWindowSocket();
    },
  },
});

/* c8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSocketStore, import.meta.hot));
}
