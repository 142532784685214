<template>
  <div data-view="app" :data-nav="navOpen ? 'open' : 'closed'">
    <NavHeader :nav-open="navOpen" @header-clicked="toggleNav" />
    <NavSidebar :nav-open="navOpen" />
    <RouterView />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import NavHeader from '@/js/components/navigation/header.vue';
import NavSidebar from '@/js/components/navigation/sidebar.vue';
import { useRootStore } from '@/js/stores/root';

export default defineComponent({
  name: 'AppView',
  components: {
    NavHeader,
    NavSidebar,
  },
  computed: {
    ...mapState(useRootStore, ['navOpen']),
  },
  methods: {
    ...mapActions(useRootStore, ['toggleNav']),
  },
});
</script>
