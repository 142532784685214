<template>
  <UtilityLink
    :href="href"
    class="icon-link"
    :target="newTab ? '_blank' : undefined"
    :rel="newTab ? 'external nofollow noreferrer noopener' : undefined"
  >
    <BooksIcon
      :name="iconName"
      :size="iconSize"
      :status="iconStatus"
      :appearance="iconAppearance"
    />
    <span v-if="text" class="link-text">{{ text }}</span>
    <span v-if="hiddenText" class="visually-hidden">{{ hiddenText }}</span>
  </UtilityLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconStatus: {
      type: String,
      default: '',
    },
    iconAppearance: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hiddenText: {
      type: String,
      default: '',
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
