<template>
  <div class="not-found">
    <header class="header-404">
      <p class="error-404">404</p>
      <h2 class="title-404">Document Not Found. That's odd!</h2>
      <p>But it's <em>bound</em> to happen sometimes.</p>
    </header>
    <strong class="not-shared"
      >This document may have been archived or made private.</strong
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PublicNotFound',
});
</script>
