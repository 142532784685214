<template>
  <BooksIcon name="spinner" status="saving muted" aria-label="saving" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilitySaving',
});
</script>
