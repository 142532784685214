<template>
  <div data-status="warning icon-text">
    <BooksIcon name="lightning" size="medium" />
    offline
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilityOffline',
});
</script>
