import { onMounted, type Ref, ref, watch } from 'vue';

import {
  BooksService,
  type DocumentReadPublic,
  type VersionReadPublic,
} from '@/js/api';

type Props = {
  publicId: Ref<string>;
  type: Ref<string>;
};

export default function useFetchPublicView(props: Props) {
  const fetching = ref(false);
  const document = ref<DocumentReadPublic | null>(null);
  const version = ref<VersionReadPublic | null>(null);

  const fetchPublicVersion = async () => {
    if (
      props.publicId.value &&
      ['document', 'version'].includes(props.type.value)
    ) {
      fetching.value = true;
      try {
        switch (props.type.value) {
          case 'document': {
            const response = await BooksService.documentReadPublic({
              publicId: props.publicId.value,
            });
            document.value = response;
            break;
          }
          case 'version': {
            const response = await BooksService.versionReadPublic({
              publicId: props.publicId.value,
            });
            version.value = response;
            break;
          }
        }
        /* c8 ignore next 2 */
      } catch {
        // swallow error
      } finally {
        fetching.value = false;
      }
    }
  };

  onMounted(fetchPublicVersion);
  watch([props.publicId, props.type], fetchPublicVersion);

  return {
    document,
    version,
    fetching,
  };
}
