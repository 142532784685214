import { isArray } from 'lodash';
import { type RouteRecordRaw, RouterView } from 'vue-router';

import PublicNotFound from '@/js/components/public/not-found.vue';
import AccountView from '@/js/views/account.vue';
import ConfirmEmailView from '@/js/views/account/confirm-email.vue';
import LoginView from '@/js/views/account/login.vue';
import RegisterUserView from '@/js/views/account/register-user.vue';
import ResetPasswordView from '@/js/views/account/reset-password.vue';
import SetNewPasswordView from '@/js/views/account/set-new-password.vue';
import AppView from '@/js/views/app.vue';
import NotFoundView from '@/js/views/app/not-found.vue';
import WriteView from '@/js/views/app/write.vue';
import PrintView from '@/js/views/print.vue';
import PublicView from '@/js/views/public.vue';
import PublicReadView from '@/js/views/public/read.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/account',
    component: AccountView,
    meta: { public: true, title: 'OddBooks' },
    children: [
      { path: '', name: 'Account', redirect: { name: 'Login', replace: true } },
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: { title: 'Login | OddBooks' },
        props: (route) => ({ next: route.query.next }),
      },
      {
        path: 'forgot',
        name: 'ResetPassword',
        component: ResetPasswordView,
        meta: { title: 'Reset Password | OddBooks' },
      },
      {
        path: 'reset/:token?',
        name: 'SetNewPassword',
        component: SetNewPasswordView,
        props: true,
        meta: { title: 'Set New Password | OddBooks' },
      },
      {
        path: 'confirm/:token?',
        name: 'ConfirmEmail',
        component: ConfirmEmailView,
        props: true,
        meta: { title: 'Confirm Email | OddBooks' },
      },
      {
        path: 'register',
        name: 'RegisterUser',
        component: RegisterUserView,
        meta: { title: 'Register User | OddBooks' },
      },
    ],
  },
  {
    path: '/auth/:pathMatch(.*)*',
    meta: { authError: true },
    // This is not actually used because the `requireAuth` handler redirects,
    // but the path is required to have a `component`.
    // Using a `redirect` here would bypass the route handler,
    // which is used to add the error message.
    component: RouterView,
  },
  {
    path: '/read',
    component: PublicView,
    meta: { title: 'OddBooks' },
    children: [
      {
        path: '',
        component: PublicNotFound,
        meta: {
          title: '404 | OddBooks',
        },
      },
      {
        path: ':type-:publicId',
        name: 'PublicRead',
        component: PublicReadView,
        props: true,
      },
      {
        path: ':pathMatch(.*)*',
        component: PublicNotFound,
        meta: {
          title: '404 | OddBooks',
        },
      },
    ],
  },
  {
    path: '/print',
    component: PrintView,
    meta: { private: true },
  },
  {
    path: '/',
    component: AppView,
    meta: { private: true, title: 'OddBooks' },
    children: [
      { path: '', name: 'App', redirect: { name: 'Write', replace: true } },
      {
        path: 'write/:panels?',
        name: 'Write',
        component: WriteView,
        props: (route) => {
          let panels = route.params.panels;
          if (isArray(panels)) {
            panels = panels.join('..');
          }
          return { panelString: panels };
        },
      },
      {
        path: '/:pathMatch(.*)*',
        component: NotFoundView,
        meta: {
          title: '404 | OddBooks',
        },
      },
    ],
  },
];

export default routes;
