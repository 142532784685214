<template>
  <IconButton
    :icon-name="account.value"
    :text="account.display"
    type="button"
    data-btn="wide"
    @click="redirect"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { BooksAuthService } from '@/js/api';
import type { AuthMethod, SocialAccountObject } from '@/js/utils/constants';

export default defineComponent({
  name: 'AccountLoginButton',
  props: {
    account: {
      type: Object as PropType<SocialAccountObject>,
      required: true,
    },
    next: {
      type: String,
      default: '/',
    },
  },
  methods: {
    /* c8 ignore next 6 */
    async redirect() {
      const { authorization_url } = await (
        BooksAuthService[this.account.authMethod] as AuthMethod
      )({ next: this.next });
      window.location.href = authorization_url;
    },
  },
});
</script>
