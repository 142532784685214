<template>
  <UtilityLoading v-if="fetching" />
  <PanelDocumentDetail
    v-else-if="document && version"
    :panel-index="panelIndex"
    :document="document"
    :version-id="version.id"
    :version-public-id="version.public_id"
    :version-title="version.title"
    :is-hidden="version.deleted !== null"
    :text="document.text"
    :modified-at="version.text_modified_at"
    :word-count="version.word_count"
    :compare-text="version.text"
  />
  <PanelNotFound v-else :panel-index="panelIndex" />
</template>

<script lang="ts">
import { toNumber } from 'lodash';
import { computed, defineComponent, toRefs } from 'vue';

import PanelDocumentDetail from '@/js/components/panels/document-detail.vue';
import PanelNotFound from '@/js/components/panels/not-found.vue';
import useFetchVersion from '@/js/composables/useFetchVersion';
import { useDocumentsStore } from '@/js/stores/documents';

export default defineComponent({
  name: 'VersionComparePanelView',
  components: {
    PanelNotFound,
    PanelDocumentDetail,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { documentId, versionId } = toRefs(props);
    const documentsStore = useDocumentsStore();

    const document = computed(() => {
      const id = toNumber(documentId.value);
      return isNaN(id) ? null : documentsStore.documentById(id);
    });
    const { version, fetching } = useFetchVersion({
      id: versionId.value,
    });

    return {
      document,
      fetching,
      version,
    };
  },
});
</script>
