<template>
  <div data-view="print">
    <div id="output" class="books-canvas"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrintView',
});
</script>
