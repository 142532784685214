import { includes, intersection, map, orderBy } from 'lodash';
import { acceptHMRUpdate, defineStore } from 'pinia';

import {
  BooksService,
  type DocumentCreate,
  type DocumentRead,
  type DocumentUpdate,
} from '@/js/api';
import { navigatePanel } from '@/js/router/panels';
import { useAlertsStore } from '@/js/stores/alerts';
import { useRootStore } from '@/js/stores/root';
import { useVersionsStore } from '@/js/stores/versions';
import { ALERT_TYPES } from '@/js/utils/constants';
import { escapeHTML } from '@/js/utils/escape';

export interface DocumentsState {
  // Map of all Documents
  documents: Map<DocumentRead['id'], DocumentRead>;
  // Map of strings of unsaved Document texts
  drafts: Map<DocumentRead['id'], string>;
  // Which document is currently in title-edit mode
  editing: DocumentRead['id'] | null;
  // List of documents whose versions are currently being fetched
  fetching: Set<DocumentRead['id']>;
}

export const useDocumentsStore = defineStore('documents', {
  state: (): DocumentsState => ({
    documents: new Map(),
    drafts: new Map(),
    editing: null,
    fetching: new Set(),
  }),

  getters: {
    documentById:
      (state) =>
      (id: number): DocumentRead | null =>
        state.documents.get(id) || null,

    draftByDocument:
      (state) =>
      (id: number): string | undefined =>
        state.drafts.get(id),

    fetchingVersions:
      (state) =>
      (id: number): boolean =>
        state.fetching.has(id),

    documentIsOpen() {
      const versionsStore = useVersionsStore();
      return function (id: number): boolean {
        const rootStore = useRootStore();
        if (includes(rootStore.panels.documents, id)) {
          return true;
        }

        const versionIds = map(versionsStore.versionsByDoc(id), 'id');
        if (intersection(rootStore.panels.versions, versionIds).length > 0) {
          return true;
        }

        return false;
      };
    },

    orderedDocuments(state) {
      return orderBy([...state.documents.values()], 'text_modified_at', [
        'desc',
      ]);
    },
  },

  actions: {
    hydrate() {
      const documents: DocumentRead[] = window.CONFIG?.DOCUMENTS || [];
      this.documents = new Map(documents.map((d) => [d.id, d]));
    },

    setDocument(document: DocumentRead) {
      this.documents.set(document.id, document);
    },

    removeDocument(id: number) {
      this.documents.delete(id);
    },

    setDraft({ text, id }: { text: string; id: number }) {
      this.drafts.set(id, text);
    },

    removeDraft(id: number) {
      this.drafts.delete(id);
    },

    setEditingDocument(id: number | null) {
      useRootStore().navOpen = true;
      this.editing = id;
    },

    async createDocument(data: DocumentCreate) {
      try {
        const response = await BooksService.documentCreate({
          requestBody: data,
        });
        this.setDocument(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async updateDocument(id: number, data: DocumentUpdate) {
      try {
        const response = await BooksService.documentUpdate({
          id,
          requestBody: data,
        });
        this.setDocument(response);
        if (response.text === this.draftByDocument(id)) {
          this.removeDraft(id);
        }
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async shareDocument(id: number, share: boolean) {
      try {
        const response = await BooksService.documentShare({
          id,
          requestBody: { share },
        });
        this.setDocument(response);
        return response;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async archiveDocument(id: number, title: string) {
      const alertsStore = useAlertsStore();
      try {
        await BooksService.documentArchive({ id });
        this.removeDocument(id);
        alertsStore.addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: `You have archived the document <strong>${escapeHTML(
            title,
          )}</strong>.`,
          safe: true,
        });
        return true;
        /* c8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async restoreFromVersion(id: number, data: DocumentUpdate) {
      const alertsStore = useAlertsStore();
      const versionsStore = useVersionsStore();
      // First auto-save a version from the current text
      const version = await versionsStore.createVersion({
        document: id,
        title: 'Autosaved Before Restoring From Version',
      });
      if (!version) return false;
      // Then replace current text with chosen version text
      const doc = await this.updateDocument(id, data);
      if (!doc) return false;
      this.removeDraft(id);
      alertsStore.addAlert({
        type: ALERT_TYPES.SUCCESS,
        message:
          'The document was autosaved before successfully restoring the selected version.',
      });
      return doc;
    },

    async addDocument() {
      const doc = await this.createDocument({
        title: 'New Document',
        text: '<p><br></p>',
      });
      if (!doc) return false;
      navigatePanel({
        to: `document-${doc.id}`,
      });
    },
  },
});

/* c8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDocumentsStore, import.meta.hot));
}
