<template>
  <PanelHeader :panel-index="panelIndex">
    <h2 class="panel-title-wrapper">
      <span class="panel-title">Document Not Found. That's odd!</span>
    </h2>
  </PanelHeader>

  <NotFound v-bind="$attrs" :panel-index="panelIndex" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import NotFound from '@/js/components/not-found.vue';
import PanelHeader from '@/js/components/panels/header.vue';

export default defineComponent({
  name: 'PanelNotFound',
  components: {
    NotFound,
    PanelHeader,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
});
</script>
