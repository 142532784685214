<template>
  <li>
    <button type="button" data-navitem="action" v-bind="$attrs">
      <slot />
    </button>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilityMenuButton',
  inheritAttrs: false,
});
</script>
