import { filter, some } from 'lodash';
import { computed, type ComputedRef, ref, watch } from 'vue';

import type { VersionRead } from '@/js/api';

export default function useHiddenVersions(
  versions: ComputedRef<VersionRead[]>,
) {
  const showHidden = ref<boolean>(false);
  const hasHiddenVersions = computed(() => some(versions.value, 'deleted'));
  const visibleVersions = computed(() => {
    if (showHidden.value) {
      return versions.value;
    }
    return filter(versions.value, { deleted: null });
  });

  watch(hasHiddenVersions, () => {
    if (!hasHiddenVersions.value) {
      showHidden.value = false;
    }
  });

  return {
    hasHiddenVersions,
    showHidden,
    visibleVersions,
  };
}
