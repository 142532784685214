<template>
  <div data-layout="account">
    <h2 data-account-title>Sign In</h2>
    <form ref="form" data-testid="form" @submit.prevent="submit">
      <AccountFieldEmail v-model="email" :disabled="submitting" />
      <AccountFieldPassword v-model="password" :disabled="submitting" />
      <div class="form-actions">
        <button type="submit" :disabled="submitting || !valid" data-btn="wide">
          Sign In
        </button>
      </div>
      <AccountLoginSocial v-if="socialLoginEnabled" :next="next" />
      <ul data-secondary-actions data-list="plain">
        <li>
          Need an account?
          <RouterLink :to="{ name: 'RegisterUser' }">
            Register now »
          </RouterLink>
        </li>
        <li>
          Forgot your password?
          <RouterLink :to="{ name: 'ResetPassword' }">
            Reset password »
          </RouterLink>
        </li>
      </ul>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BooksAuthService } from '@/js/api';
import AccountFieldEmail from '@/js/components/account/field-email.vue';
import AccountFieldPassword from '@/js/components/account/field-password.vue';
import AccountLoginSocial from '@/js/components/account/login-social.vue';

export default defineComponent({
  name: 'AccountLoginForm',
  components: { AccountFieldEmail, AccountFieldPassword, AccountLoginSocial },
  props: {
    next: {
      type: String,
      default: '/',
    },
    socialLoginEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      submitting: false,
      valid: false,
    };
  },
  watch: {
    email() {
      this.checkValidity();
    },
    password() {
      this.checkValidity();
    },
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        await BooksAuthService.authCookieLogin({
          formData: {
            username: this.email,
            password: this.password,
          },
        });
        window.location.href = this.next;
        return true;
      } catch (err) {
        this.password = '';
        return false;
      } finally {
        this.submitting = false;
      }
    },
    checkValidity() {
      const form = this.$refs.form as HTMLFormElement;
      this.valid = form.checkValidity();
    },
  },
});
</script>
