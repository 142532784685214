<template>
  <h3 data-account-title="social">Or sign in with…</h3>
  <ul data-books="login" data-list="plain">
    <li v-for="account in socialAccounts" :key="account.value">
      <AccountLoginButton :account="account" :next="next" />
    </li>
  </ul>
</template>

<script lang="ts">
import { values } from 'lodash';
import { defineComponent } from 'vue';

import AccountLoginButton from '@/js/components/account/login-button.vue';
import {
  SOCIAL_ACCOUNTS,
  type SocialAccountObject,
} from '@/js/utils/constants';

export default defineComponent({
  name: 'AccountLoginSocial',
  components: {
    AccountLoginButton,
  },
  props: {
    next: {
      type: String,
      default: '/',
    },
  },
  computed: {
    socialAccounts(): SocialAccountObject[] {
      return values(SOCIAL_ACCOUNTS);
    },
  },
});
</script>
