<template>
  <time :datetime="datetime">{{ rendered }}</time>
</template>

<script lang="ts">
import { utc, UTCDateMini } from '@date-fns/utc';
import {
  format,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilityDate',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    date(): Date {
      return this.value ? parseISO(this.value, { in: utc }) : new UTCDateMini();
    },
    datetime(): string {
      return format(this.date, "yyyy-MM-dd'T'HH:mm:ss.SSSXX");
    },
    rendered(): string {
      let day;
      if (isToday(this.date)) {
        day = 'Today';
      } else if (isYesterday(this.date)) {
        day = 'Yesterday';
      } else if (isThisWeek(this.date)) {
        day = format(this.date, 'EEEE');
      } else if (isThisYear(this.date)) {
        day = format(this.date, 'MMM d');
      } else {
        day = format(this.date, 'MMM d, yyyy');
      }
      const time = format(this.date, 'h:mma').toLowerCase();
      return `${day} @ ${time}`;
    },
  },
});
</script>
