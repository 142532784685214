<template>
  <li>
    <UtilityLink
      :href="href"
      :disabled="disabled"
      data-navitem="action"
      v-bind="$attrs"
    >
      <slot />
    </UtilityLink>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilityMenuLink',
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
