<template>
  <AccountConfirmEmailForm :token="token" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccountConfirmEmailForm from '@/js/components/account/confirm-email-form.vue';

export default defineComponent({
  name: 'ConfirmEmailView',
  components: { AccountConfirmEmailForm },
  props: {
    token: { type: String, default: undefined },
  },
});
</script>
