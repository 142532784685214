// eslint-disable-next-line import/no-unresolved
import sassConfigRaw from './../../../built/json.css?raw';

// Parse sass-json string into JSON
const startMarker = '/*! json-encode:';
const endMarker = '*/';
const start = sassConfigRaw.indexOf(startMarker);
const end = sassConfigRaw.indexOf(endMarker, start);
const sassConfig = JSON.parse(
  sassConfigRaw.slice(start + startMarker.length, end),
);

export const getColors = () => {
  const colors = new Map();
  const keys = Object.keys(sassConfig.colors || {});
  for (const categoryName of keys) {
    colors.set(categoryName, sassConfig.colors[categoryName]);
  }
  return colors;
};
