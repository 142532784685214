<template>
  <div class="header-wrapper">
    <header data-header="panel">
      <div class="header-menu">
        <slot name="menu" />
      </div>

      <div class="header-title">
        <slot />
      </div>
      <UtilityOffline v-if="!connected" />

      <div class="panel-close">
        <IconButton
          icon-name="cross"
          icon-size="small"
          hidden-text="close panel"
          title="close panel"
          @click="closePanel"
        />
      </div>
    </header>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';

import { closePanel } from '@/js/router/panels';
import { useSocketStore } from '@/js/stores/socket';

export default defineComponent({
  name: 'PanelHeader',
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(useSocketStore, ['connected']),
  },
  methods: {
    closePanel() {
      closePanel(this.panelIndex);
    },
  },
});
</script>
