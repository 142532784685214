<template>
  <main data-view="account">
    <div data-splash>
      <header data-layout="banner">
        <IconLink
          href="/"
          icon-name="logo"
          hidden-text="OddBooks.app"
          rel="home"
        />
        <h1 class="main-title">
          <span>Worry-Free Writing</span>
          <span class="amp"> & </span>
          <span>No-Mess Collaboration</span>
        </h1>
      </header>
      <div data-layout="graphic" role="presentation">
        <IconSplashGraphic />
      </div>
      <RouterView />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountView',
});
</script>
