<template>
  <UtilityMenu id="user-menu">
    <template #icon>
      <UserIcon
        :color="user?.avatar_color"
        hidden-text="account menu"
        data-insert-trigger
        data-avatar="button"
      />
    </template>
    <UtilityMenuLink href="/write/account" @click.prevent="goToMyAccount"
      >My Account</UtilityMenuLink
    >
    <hr />
    <UtilityMenuButton @click="logOut">Sign Out</UtilityMenuButton>
  </UtilityMenu>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import UserIcon from '@/js/components/icons/user-icon.vue';
import { navigatePanel } from '@/js/router/panels';
import { useUserStore } from '@/js/stores/user';

export default defineComponent({
  name: 'UserMenu',
  components: { UserIcon },
  computed: {
    ...mapState(useUserStore, ['user']),
  },
  methods: {
    ...mapActions(useUserStore, ['logOut']),
    goToMyAccount(event: PointerEvent) {
      if (event.target instanceof HTMLElement) {
        event.target?.blur();
      }
      navigatePanel({
        to: 'account',
      });
    },
  },
});
</script>
