<template>
  <PanelHeader :panel-index="panelIndex">
    <template #menu>
      <PanelHeaderMenu :panel-index="panelIndex" />
    </template>

    <h2 class="panel-title-wrapper can-truncate">
      <span class="panel-title">My Account</span>
    </h2>
  </PanelHeader>

  <div class="content-wrapper">
    <div class="scroll-content" data-panel="scroll-content">
      <UserIcon :color="user?.avatar_color" size="xlarge" data-avatar="image" />
      <form
        ref="form"
        data-testid="name-form"
        data-form="autosave"
        @submit.prevent="flushUpdateName"
      >
        <AccountFieldName
          v-model="name"
          :disabled="!connected"
          @focusout="flushUpdateName"
        />
        <UtilitySaveIndicator :state="savingName" />
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from 'lodash';
import { computed, defineComponent, ref, watch } from 'vue';

import AccountFieldName from '@/js/components/account/field-name.vue';
import UserIcon from '@/js/components/icons/user-icon.vue';
import PanelHeader from '@/js/components/panels/header.vue';
import PanelHeaderMenu from '@/js/components/panels/header-menu.vue';
import { useSocketStore } from '@/js/stores/socket';
import { useUserStore } from '@/js/stores/user';
import { SAVE_STATE, type SaveState } from '@/js/utils/constants';

export default defineComponent({
  name: 'PanelMyAccount',
  components: {
    AccountFieldName,
    PanelHeader,
    PanelHeaderMenu,
    UserIcon,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();
    const socketStore = useSocketStore();
    const connected = computed(() => socketStore.connected);
    const user = computed(() => userStore.user);
    const username = computed(() => user.value?.name ?? '');
    const name = ref(username.value);
    const savingName = ref<SaveState>(SAVE_STATE.INACTIVE);
    const form = ref<HTMLFormElement | null>(null);

    const updateName = async () => {
      if (!form.value?.checkValidity()) return;
      if (name.value === username.value) return;
      savingName.value = SAVE_STATE.SAVING;
      if (await userStore.update({ name: name.value })) {
        savingName.value = SAVE_STATE.SAVED;
      } else {
        savingName.value = SAVE_STATE.INACTIVE;
      }
    };
    const debouncedUpdateName = debounce(updateName, 3000);
    const flushUpdateName = () => {
      debouncedUpdateName.flush();
    };

    watch(name, debouncedUpdateName);
    watch(username, () => {
      if (username.value !== name.value) name.value = username.value;
    });

    return {
      connected,
      form,
      flushUpdateName,
      name,
      savingName,
      user,
    };
  },
});
</script>
