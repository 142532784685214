<template>
  <UtilityFormField
    title="Name"
    field-id="name"
    description="(used for collaboration)"
    :error="showValidation ? error : undefined"
  >
    <input
      id="name"
      ref="input"
      :value="modelValue"
      :disabled="disabled"
      type="text"
      name="name"
      data-input="text"
      autocomplete="name"
      required
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
      @blur="showValidation = true"
    />
  </UtilityFormField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountFieldName',
  props: {
    modelValue: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  emits: {
    'update:modelValue': (_value: string) => true,
  },
  data() {
    return {
      error: '',
      showValidation: false,
    };
  },
  watch: {
    modelValue() {
      const input = this.$refs.input as HTMLInputElement;
      this.error = input.validationMessage;
    },
  },
});
</script>
