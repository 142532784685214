export const escapeHTML = (html: string) => {
  const el = document.createElement('div');
  el.innerText = html;
  return el.innerHTML;
};

export const removeCursorSpans = (html: string) => {
  const cursorSpan = /<span class="ql-cursor">.*?<\/span>/gi;
  const bom = /\ufeff/gi;
  return html.replace(cursorSpan, '').replace(bom, '');
};
