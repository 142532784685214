<template>
  <AccountLoginForm :next="next" :social-login-enabled="socialLoginEnabled" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AccountLoginForm from '@/js/components/account/login-form.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    AccountLoginForm,
  },
  props: {
    next: {
      type: String,
      default: '/',
    },
  },
  computed: {
    socialLoginEnabled(): boolean {
      return window.CONFIG?.SOCIAL_LOGIN_ENABLED || false;
    },
  },
});
</script>

<style lang="scss" scoped>
h1 {
  font-size: var(--responsive);
}
</style>
