<template>
  <Transition name="fade" mode="out-in">
    <UtilitySaving v-if="internalState === SAVE_STATE.SAVING" />
    <UtilitySaved v-else-if="internalState === SAVE_STATE.SAVED" />
  </Transition>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, toRefs } from 'vue';

import { SAVE_STATE, type SaveState } from '@/js/utils/constants';

/**
 * Given a state of `saving`, `saved` or `inactive`, shows a spinner while
 * saving, for a minimum of 1 second, and then a saved checkmark for a minimum
 * of 3 seconds on success.
 */
export default defineComponent({
  name: 'UtilitySaveIndicator',
  props: {
    state: {
      type: String as PropType<SaveState>,
      default: SAVE_STATE.INACTIVE,
    },
  },
  setup(props) {
    const { state } = toRefs(props);
    const internalState = ref<SaveState>(state.value);
    const timer = ref<number>();
    const start = ref<number>();

    return {
      SAVE_STATE,
      internalState,
      start,
      timer,
    };
  },
  watch: {
    state(state) {
      switch (state) {
        case SAVE_STATE.SAVING:
          this.internalState = SAVE_STATE.SAVING;
          window.clearTimeout(this.timer);
          this.start = Date.now();
          break;
        case SAVE_STATE.SAVED: {
          const end = Date.now();
          this.timer = window.setTimeout(
            () => this.showSaved(),
            Math.max(1000 - (end - (this.start ?? 0)), 0),
          );
          break;
        }
        case SAVE_STATE.INACTIVE:
          this.internalState = SAVE_STATE.INACTIVE;
          break;
      }
    },
  },
  methods: {
    showSaved() {
      this.internalState = SAVE_STATE.SAVED;
      this.timer = window.setTimeout(() => {
        this.internalState = SAVE_STATE.INACTIVE;
      }, 3000);
    },
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--timing-slow) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
