import * as jose from 'jose';

export function decodeToken(token: string | undefined, targetAud: string) {
  try {
    if (!token) throw 'No token provided';
    const claims = jose.decodeJwt(token);
    const { email, exp, aud } = claims as {
      email: string;
      exp: number;
      aud: string;
    };
    let isExpired = false;
    if (exp <= new Date().getTime() / 1000) isExpired = true;
    const isValid = !isExpired && aud === targetAud;
    return {
      email,
      isValid,
    };
  } catch (error) {
    return { isValid: false, error };
  }
}
