import type { NavigationGuard, RouteLocationNormalized } from 'vue-router';

import { useAlertsStore } from '@/js/stores/alerts';
import { useUserStore } from '@/js/stores/user';
import { ALERT_TYPES } from '@/js/utils/constants';

type RouteTitle = string | ((r: RouteLocationNormalized) => string) | undefined;

// Enforce private routes that require authentication
export const requireAuth: NavigationGuard = (to) => {
  // This goes through the matched routes from last to first,
  // finding the closest route title.
  let nearestTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)?.meta?.title as RouteTitle;

  // Update document title
  if (typeof nearestTitle === 'function') {
    nearestTitle = nearestTitle(to);
  }
  document.title = nearestTitle || 'OddBooks';

  // Handle authentication errors
  const isAuthError = to.matched.some((record) => record?.meta?.authError);
  // Restrict private routes
  const isPrivate = to.matched.some((record) => record?.meta?.private);
  const isPublic = to.matched.some((record) => record?.meta?.public);
  const userStore = useUserStore();
  const isAuthenticated = userStore.user !== null;
  if (isAuthError) {
    if (!isAuthenticated) {
      useAlertsStore().addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Uh-oh! There was an error logging in to your account.',
      });
    }
    return { name: 'App', replace: true };
  }
  if (isPublic && isAuthenticated) {
    return { name: 'App', replace: true };
  }
  if (isPrivate && !isAuthenticated) {
    return { name: 'Login', query: { next: to.fullPath }, replace: true };
  }
  return true;
};
